@mixin field-error($important)
  @if $important
    background: lighten($color-alert-background, 47%) !important
    border-color: $color-alert-background !important
    outline-color: $color-alert-background !important
  @else
    background: lighten($color-alert-background, 47%)
    border-color: $color-alert-background
    outline-color: $color-alert-background

input, textarea, select
  border: rem(1px) solid $color-text-secondary
  border-radius: rem(2px)

  &[disabled]
    background: #eee !important

select
  padding: 0.6rem
  &:not(.ng-dirty.ng-invalid)
    background-color: #ffffff

button[disabled]
  cursor: default
  opacity: .3

.error-message
  color: $color-alert-background
  margin: 0.5rem 0

.mat-field
  .mat-icon-button
    height: initial
    width: initial

input:enabled.on-score,
select:enabled.on-score,
select:enabled:not(.ng-dirty.ng-invalid).on-score,
.on-score input:enabled,
.on-score select:enabled,
.on-score select:enabled:not(.ng-dirty.ng-invalid),
.on-score .number-input:enabled
  border: 1px solid $color-link-action
  background-color: rgba($color-link-action, 0.05)

input, textarea, select
  &.ng-dirty.ng-invalid, &.error
    @include field-error(false)

.mandatory-field-error
  @include field-error(true)

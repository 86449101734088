.icon
  polygon,
  path
    transition: fill $transition-duration ease-in-out

  &-sortable
    color: #d8d8d8
    vertical-align: middle
    margin-left: 6px

    .is-sortup &-up
      fill: #005074

    .is-sortdown &-down
      fill: #005074

@import "tailwindcss/build/tailwind.css"
@import "shared/index"

@import "core/defaults"
@import "core/atomic"
@import "core/typography"

@import "modules/account"
@import "modules/buttons"
@import "modules/filters"
@import "modules/forms"
@import "modules/highcharts"
@import "modules/icons"
@import "modules/material"
@import "modules/module"
@import "modules/plating"
@import "modules/primeng"
@import "modules/rail"
@import "modules/tooltip"
@import "modules/tables"
@import "modules/printing"
@import "../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css"
@import "material.sass"
@import "../../node_modules/croppie/croppie.css"

// Generated with: https://google-webfonts-helper.herokuapp.com/fonts/assistant?subsets=latin
// Source: https://github.com/majodev/google-webfonts-helper

// assistant-200 - latin
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 200;
  src:
    local('Assistant ExtraLight'), local('Assistant-ExtraLight'),
    url('/assets/fonts/assistant-v2-latin-200.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('/assets/fonts/assistant-v2-latin-200.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// assistant-300 - latin
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 300;
  src:
    local('Assistant Light'), local('Assistant-Light'),
    url('/assets/fonts/assistant-v2-latin-300.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('/assets/fonts/assistant-v2-latin-300.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// assistant-regular - latin
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src:
    local('Assistant'), local('Assistant-Regular'),
    url('/assets/fonts/assistant-v2-latin-regular.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('/assets/fonts/assistant-v2-latin-regular.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// assistant-600 - latin
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  src:
    local('Assistant SemiBold'), local('Assistant-SemiBold'),
    url('/assets/fonts/assistant-v2-latin-600.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('/assets/fonts/assistant-v2-latin-600.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// assistant-700 - latin
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  src:
    local('Assistant Bold'), local('Assistant-Bold'),
    url('/assets/fonts/assistant-v2-latin-700.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('/assets/fonts/assistant-v2-latin-700.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// assistant-800 - latin
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 800;
  src:
    local('Assistant ExtraBold'), local('Assistant-ExtraBold'),
    url('/assets/fonts/assistant-v2-latin-800.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('/assets/fonts/assistant-v2-latin-800.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

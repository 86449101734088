@import 'variables';

.text-primary {
  color: $color-text;
}

.text-secondary {
  color: $color-text-secondary;
}

.text-secondary-darker {
  color: $color-text-secondary-darker;
}

.bg-primary {
  background: $default-primary-color;
}

.bg-secondary {
  background: $default-secondary-color;
}

.text-blue-500 {
  color: $blue-500;
}

.text-blue-400 {
  color: $blue-400;
}

.color-danger {
  color: $color-alert-background;
}

.color-success {
  color: $color-success;
}

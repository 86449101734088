@use "sass:math"

.filters
  display: flex
  flex-direction: column
  align-items: flex-start

  &
    background: $color-filter-background
    margin-bottom: rem($baseline)
    padding: rem($baseline * 1.5)
    width: 100%

  .title
    font-size: rem(16px)
    font-weight: 600
    margin: rem(0 0 $baseline)

  &-search
    width: 100%

    .mat-mdc-text-field-wrapper
      background-color: #fff
      padding-left: 0
      .mat-mdc-form-field-flex
        .mdc-floating-label
          font-size: 14px
          top: 23px
          &--float-above
            top: 15px
    .mat-mdc-form-field
      background: $white !important
      padding: 0 1rem 0 0.5rem
      height: 4.2rem
      &-input-control
        font-size: 14px
      &-wrapper, &-focus-overlay
        background-color: #fff
      &-infix
        min-height: 3.6rem
        padding: 1rem 0 !important
      &-subscript-wrapper
        height: 4px
      .mat
        &-form-field-underline
          span
            background-color: $color-filter-selected-background

        &-datepicker-toggle
          span
            font-size: 2rem
            color: $color-filter-selected-background

    .mdc-text-field--filled .mdc-line-ripple:after
      border-bottom-width: 3px
      border-bottom-color: $color-filter-selected-background

    .floatingLabel
      position: absolute
      font-size: 11px
      color: $grey-dark
      margin: -0.2rem 0 0 0.5rem
      animation: fadeIn ease 1s

    @keyframes fadeIn
      0%
        opacity: 0
      100%
        opacity: 1

    .search
      input
        background: $white url("/assets/icons/search.svg") 140% 50% no-repeat
        background-size: 50% 50%
        // border: rem(1px) solid $color-module-border
        border: none
        font-size: rem($baseline)
        margin: 0
        padding: 1.5rem 0 0.5rem 0.5rem
        width: 100%

      .ui-inputtext
        background: $white url("/assets/icons/calendar.svg") 120% 50% no-repeat
        background-size: 50% 50%
        margin-right: rem($baseline)
        width: rem(140px)

    .filter
      float: right
      margin: rem(8px) rem($baseline) 0 0

.ptn
  padding-top: 0 !important

.filter-buttons
  overflow: hidden
  max-width: 100%

  .filter
    margin: rem($baseline) rem($baseline) 0 0

.filter
  border: rem(1px) solid $color-filter-unselected-border
  border-radius: rem(20px)
  color: $color-filter-unselected-text
  cursor: pointer
  display: inline-block
  float: left
  font-size: rem($baseline)
  line-height: normal
  padding: rem(5px 15px)
  text-transform: uppercase

  &.selected
    background: $color-filter-selected-background
    border-color: $color-filter-selected-border
    color: $color-filter-selected-text

.filterapplybtn
  background: $color-filter-apply-background
  color: $color-filter-apply-text

mat-calendar
  .mat-calendar-header
    padding: 4px

    .mat-calendar-controls
      background-color: $color-filter-selected-background !important
      border: 1px solid $white
      border-radius: 2px
      margin: -1% calc(math.div(33%, 7) - 16px)

.mat
  &-focused
    label
      color: $grey-dark !important

  &-calendar
    &-body-cell
      &.mat-calendar-body-active.ng-star-inserted > div,
      &:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected)
        background-color: $blue-500 !important

    &-arrow
      border-top-color: $white

    &-period-button.mat-button,
    &-previous-button.mat-icon-button,
    &-next-button.mat-icon-button
      color: $white

    &-body
      &-selected,
      &-today:not(.mat-calendar-body-selected),
      &-active
        border-color: $blue-500 !important

    &-previous,
    &-next,
    &-period
      &-button
        &:hover,
        &.cdk-focused.cdk-keyboard-focused
          background-color: $white
          color: $black

.account, .auth
  position: relative
  min-height: 100%
  width: rem(1260px)
  @media screen and (max-width: $desktop)
    width: rem(100%)
  @media screen and (max-width: 2600px)
    width: 100%
    max-width: rem(1400px)

  main
    display: block
    margin-left: rem(275px + $baseline * 2)
    @media screen and (max-width: $desktop)
      margin-left: 0

    &.is-mobile
      margin-left: 0

  &.is-collapsedrail main
    margin-left: rem(182px + $baseline * 2)
    @media screen and (max-width: $desktop)
      margin-left: 0

.master-data
  tr
    th
      vertical-align: top
      width: 15%
    td
      vertical-align: top
      width: 35%

      ul
        list-style-type: none
        margin: 0
        padding: 0

.is-collapsedrail-show
  display: none
  .is-collapsedrail &
    display: block

.is-collapsedrail-hide
  display: block
  .is-collapsedrail &
    display: none

.disclaimer
  color: $black
  font-size: rem($baseline * 1.2)

mpv-open-accounts
  .module.hidden
    display: none

    @media screen and (min-width: 540px)
      display: block

    @media print and (min-width: 540px)
      display: block

.unstyledbtn
  background-color: transparent
  border: 0
  padding: 0

.btnprimary, .btn-primary
  background-color: $blue-500
  border-radius: rem(15px) !important
  color: $white !important
  cursor: pointer !important
  line-height: initial
  padding: rem(5px 20px) !important

.btnsecondary, .btn-secondary
  background-color: $white
  border: 1px solid $blue-500
  border-radius: rem(15px) !important
  color: $blue-500 !important
  cursor: pointer
  padding: rem(5px 20px) !important

.btn-primary
  transition: all 0.3s
  &:hover, &.selected
    background-color: $white
    border: 1px solid $blue-500
    color: $blue-500 !important

.btn-secondary
  transition: all 0.5s
  &:hover, &.selected
    background-color: $blue-500
    color: $white !important

.btn-square
  border-radius: rem(3px)

.btnicon
  .icon
    align-items: center
    border: 1px solid $blue-500
    border-radius: 50%
    display: flex
    height: rem(15px)
    justify-content: center
    width: rem(15px)
  .icon-chevron-up
    svg
      top: 0

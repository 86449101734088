.dn
  display: none
.db
  display: block !important
.dib
  display: inline-block

.cup
  cursor: pointer

.wsnw
  white-space: nowrap

.tal
  text-align: left
.tac
  text-align: center
.tar
  text-align: right

.mt0
  margin-top: 0

.pl0
  padding-left: 0

.py2
  padding: 0.612rem

.avatar
  border-radius: 50%
  height: rem(55px)
  width: rem(55px)

.notification
  background: $color-alert-background
  color: $color-alert-text
  border-radius: 50%
  font-size: rem(10px)
  font-weight: bold
  line-height: rem(15px)
  height: rem(17px)
  text-decoration: none
  width: rem(17px)

.screenreaderonly
  border: 0
  clip: rect(0 0 0 0)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  width: 1px

.zebrastripe
  > tr:not(.row-inner-content):nth-of-type(odd)
    background: $color-row-odd

  > tr:not(.row-inner-content):nth-of-type(even)
    background: $color-row-even

.split
  text-align: right
  > .left
    float: left
    text-align: left

.loadering
  animation: spin 400ms infinite linear
  display: inline-block
  border: rem(2px) solid
  border-color: $color-link-icon $color-link-icon transparent transparent
  border-radius: 50%
  height: rem(16px)
  width: rem(16px)
  &--small
    height: 1rem
    width: 1rem
  &--medium
    height: 4rem
    width: 4rem

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.footerDisclaimer
  a
    color: #0071B8
    font-family: Verdana, Geneva, Tahoma, sans-serif
    &:focus, &:hover
      text-decoration: underline

.profile-modal, .preview-banner-modal
  .p-dialog-titlebar-icon
    background-color: initial !important
    border: none !important
  .ui-widget-header
    background-color: $color-background
    color: $color-subnav-link
    border-bottom: 2px solid $color-border !important
    align-items: center
    display: flex
    justify-content: space-between
    padding: 2rem 2rem 1.5rem 2.5rem !important
    .p-dialog-title
      flex: 1
  .ui-widget-content
    padding-bottom: rem(10px)

.p-dialog
  .p-dialog-header-icon
    color: $black !important
    &:hover
      opacity: 0.7 !important

.btn
  border-radius: rem(15px)
  color: $white !important
  cursor: pointer
  line-height: initial
  padding: rem(5px 20px) !important
  font-size: 16px

  &.btn-download
    background-color: $btn-green
    display: flex
    align-items: center
    &:hover
      background-color: $btn-green-hover
    &:active
      background-color: $btn-green-click
    .icon
      align-items: center
      height: rem(15px)
      justify-content: center
      width: rem(25px)

  &.btn-download-absolute-top
    position: absolute
    z-index: 10
    right: 0
    top: 25px

  &.btn-download-absolute-bottom
    position: absolute
    z-index: 10
    right: 0
    bottom: 50px

  @media (max-width: $screen-lg)
    &.btn-download-absolute-top
      right: 50% !important
      top: 1.5rem !important
      transform: translateX(50%)
      white-space: nowrap

@media (max-width: $screen-md)
  .max-w-50
    max-width: 50%

th,
td
  text-align: left
  border: 0

.tbl
  &
    border-spacing: 0
    font-size: rem(14px)
    width: 100%

  & + &
    margin-top: 12px

  > * > * > *
    padding: rem(9px 10px)


  &-head
    > * > th
      color: #4A4A4A
      font-size: rem(12px)
      font-weight: 600
      text-transform: uppercase

    > *:last-child > *
      border-bottom: 1px solid #D7D7D7

    // SortColumnDirective support
    > tr > th.sort
      cursor: pointer
      transition: border-color $transition-duration ease-in-out
      user-select: none
      white-space: nowrap
      &:hover
        border-bottom-color: #005074

  &-body
    > * > *
      color: #4a4a4a
      vertical-align: middle
    .cell-loadering 
      display: table-cell !important
      padding: 10rem 0 !important

  &-foot
    > *:first-child > *
      border-top: 1px solid #D7D7D7

  &--sticky
    .sticky
      @media screen and (min-width: $screen-md)
        position: relative !important
      @media screen and (max-width: $screen-sm)
        position: sticky !important
    thead
      .sticky
        background-color: white
        z-index: 12 !important
    .zebrastripe
      > *:nth-child(odd) td
        background: $color-row-odd

      > *:nth-child(even) td
        background: $color-row-even

.cell
  &-ellipsis
    transition: color $transition-duration ease-in-out
    color: #005074
    cursor: pointer
    &:hover
      color: lighten(#005074, 30%)

  &-account
    &-number
      color: #4a4a4a
      font-size: rem(13px)
    a:hover &-number
      text-decoration: underline solid #4a4a4a

    &-name,
    a
      color: #0071B8

.account-name
  color: #0071B8

  &-firstrowonly
    display: none
    tr:first-child &
      display: initial

tr.unread
  td
    &
      border-bottom: 1px solid #ffffff

    &:first-child
      span.unread-notification
        background: $color-alert-background
        bottom: 0
        height: 100%
        left: 0
        position: absolute
        top: 0
        width: rem(2px)

.positions-by-weight-title
  color: $color-text-secondary
  font-size: $baseline
  margin: 0
  padding: 0
  text-transform: uppercase

$positionweight-rank-1: #00567D
$positionweight-rank-2: #4587a1
$positionweight-rank-3: #6aa4b7
$positionweight-rank-4: #8fc0cc
$positionweight-rank-5: #c9ebee
$positionweight-rank-6: #bf6318
$positionweight-rank-7: #cb8642
$positionweight-rank-8: #d69f66
$positionweight-rank-9: #dfb88d
$positionweight-rank-10: #eddec5
$positionweight-rank-11: #f6efe2

.positionweight
  display: block
  height: rem(16px)
  width: rem(16px)

  &.rank-1
    background: $positionweight-rank-1
    rect.highcharts-point
      fill: $positionweight-rank-1
      stroke: $positionweight-rank-1

  &.rank-2
    background: $positionweight-rank-2
    rect.highcharts-point
      fill: $positionweight-rank-2
      stroke: $positionweight-rank-2

  &.rank-3
    background: $positionweight-rank-3
    rect.highcharts-point
      fill: $positionweight-rank-3
      stroke: $positionweight-rank-3

  &.rank-4
    background: $positionweight-rank-4
    rect.highcharts-point
      fill: $positionweight-rank-4
      stroke: $positionweight-rank-4

  &.rank-5
    background: $positionweight-rank-5
    rect.highcharts-point
      fill: $positionweight-rank-5
      stroke: $positionweight-rank-5

  &.rank-6
    background: $positionweight-rank-6
    rect.highcharts-point
      fill: $positionweight-rank-6
      stroke: $positionweight-rank-6

  &.rank-7
    background: $positionweight-rank-7
    rect.highcharts-point
      fill: $positionweight-rank-7
      stroke: $positionweight-rank-7

  &.rank-8
    background: $positionweight-rank-8
    rect.highcharts-point
      fill: $positionweight-rank-8
      stroke: $positionweight-rank-8

  &.rank-9
    background: $positionweight-rank-9
    rect.highcharts-point
      fill: $positionweight-rank-9
      stroke: $positionweight-rank-9

  &.rank-10
    background: $positionweight-rank-10
    rect.highcharts-point
      fill: $positionweight-rank-10
      stroke: $positionweight-rank-10

  &.rank-11
    background: $positionweight-rank-11
    rect.highcharts-point
      fill: $positionweight-rank-11
      stroke: $positionweight-rank-11

.table-wrapper
  width: 100%
  overflow: auto


@media print

  printing
    display: block
  .no
    &-printer
      display: none

  .cell
    &-account
      &-name
        display: none
  .show
    &-printer
      display: block

  .whitespace
    &-normal div
      font-size: 10px


@page
  margin: 1cm
  size: landscape

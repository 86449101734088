.printing
  display: none

.table-print thead th,
.table-print tbody td
  border-color: $color-hr !important

@media print
  *
    -webkit-print-color-adjust: exact !important
    color-adjust: exact !important
    print-color-adjust: exact !important
    forced-color-adjust: exact !important

  @page
    margin: 1cm
    size: landscape

  body
    background-color: white !important
    font-size: 12px
    margin: 0

  .rail, .plating, .app-header, nav, .print-hidden, #survicate-box
    display: none !important

  .print-initial
    position: initial

  .account
    main
      margin-left: 0!important

  .table-wrapper
    max-height: initial !important

  mpv-open-accounts, .print-block
    display: block !important

  .footerDisclaimer
    font-size: 12px
    text-align: justify

  .title-print
    background: $color-filter-unselected-text
    color: $white
    font-weight: 600
    margin-bottom: 10px
    padding: 5px 10px
    text-transform: uppercase

  .table-print
    border-spacing: 0
    border-collapse: unset
    line-height: 1.1
    page-break-inside: always
    width: 100%

  .table-print thead th,
  .table-print tbody td
    border: 1px solid $color-hr
    height: initial !important
    padding: 2px 4px

  #positions-table tr:not(.row-inner-content) > td:first-of-type
    display: table-cell !important

  .table-print thead .sticky
    background-color: $color-row-odd !important
    position: initial !important

  .table-print thead
    background-color: #f2f2f2

  .table-print tr
    page-break-inside: initial

  .table-print thead th, .table-print tbody td
    border: 1px solid #d7d7d7
    padding: 4px

  .printing
    display: block

  .no
    &-printer
      display: none

  .cell
    &-account
      &-name
        display: none
  .show
    &-printer
      display: block

  .whitespace
    &-normal div
      font-size: 10px

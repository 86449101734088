:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
}

$font-stack-sans-serif: 'Assistant', sans-serif;

$baseline: 12px;

$rem-baseline: 10px;
$rem-fallback: true; // Required for IE11's line-height, where we use the mixin to get the px included.

// Responsive sizes!
$desktop: 1279px;
$compactdesktop: 769px;
$mobile: 850px;
$mediummobile: 600px;
$tinymobile: 320px;

//recommended breakpoints
$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-air-portrait: 1180px;
$screen-xl: 1280px;
$screen-2xl: 1536px;

$color-background: #fbfbfb;
$color-border: #ebebeb;
$color-text: #001420;
$color-text-secondary: #757575;
$color-text-secondary-darker: #484848;
$blue-400: #00c2ff;
$blue-500: #007bc7;
$color-link-icon: #085173;
$color-link-action: #11a9dc;
$color-link-icon-inactive: #757575;
$color-link-profile: $blue-400;
$color-success: #00ba8d;

$color-hr: #d7d7d7;
$color-vr: #eaeaea;

$color-row-odd: #f2f2f2;
$color-row-even: #fff;

$default-primary-color: #444;
$color-primary-background-text: #ffffff;
$default-secondary-color: #bbb;

$color-plating-figures-background: #ffffff;
$color-plating-text: #000000;
$color-plating-text-meta: #757575;
$color-plating-label: #5b5b5b;
$color-plating-hr: #ebebeb;

$color-subnav-link: #011420;

$rail-shadow: rem(0px 0px 10px 0px) rgba($color-text, 0.4);
$color-rail-borders: #d7d7d7;
$color-rail-account-links: #000000;
$color-rail-account-links-secondary: #5b5b5b;

$color-alert-background: #ff3d00;
$color-alert-text: #ffffff;

$color-module-border: #ebebeb;
$color-module-footer: #5b5b5b;
$color-module-title: #001420;
$color-module-action-text: #666666;

$color-filter-background: #f2f2f2;
$color-filter-unselected-border: #00567d;
$color-filter-unselected-background: transparent;
$color-filter-unselected-text: #00567d;
$color-filter-selected-border: #00567d;
$color-filter-selected-background: #00567d;
$color-filter-selected-text: #ffffff;
$color-filter-apply-background: #007700;
$color-filter-apply-text: #ffffff;

$black: #000;
$white: #fff;
$red: #ff0000;
$grey-dark: #6e6e6e;
$gray-light: #bdbdbd;

$transition-duration: 0.15s;
$blue-600: #374761;
$btn-green: #00ba8d;
$btn-green-hover: #00dda8;
$btn-green-click: #03a57e;

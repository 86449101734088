// Styles to support Angular Material components

.cdk
  &-overlay

    &-container
      position: fixed
      z-index: 1000
      pointer-events: none
      top: 0
      left: 0
      height: 100%
      width: 100%

    &-backdrop
      -webkit-tap-highlight-color: transparent
      bottom: 0
      left: 0
      opacity: 0
      pointer-events: auto
      position: absolute
      right: 0
      top: 0
      transition: opacity .4s cubic-bezier(.25,.8,.25,1)
      z-index: 1000

      &-showing
        opacity: 1

    &-pane
      box-sizing: border-box
      display: flex
      max-height: 100%
      max-width: 100%
      pointer-events: auto
      position: absolute
      z-index: 1000

.mat

  &-datepicker
    &-content
      background: #fff
      color: #000
    &-toggle-active
      color: $blue-500

  &-calendar
    // Must be 100%, not unset, or IE11 will not give calendar enough height
    height: 100% !important
    &-body-selected
      background-color: $blue-500

  &-mdc-icon-button
    height: 25px !important
    padding: 0 !important
    width: 25px !important

.mat-mdc-button:not(:disabled)
  color: #0000008a !important

.example-header-nav-controls span
  color: black !important
  letter-spacing: normal !important

.mat-mdc-snack-bar-container
  border-radius: 4px
  margin: 24px !important
  max-width: 33vw
  min-height: 48px
  .mdc-snackbar__surface
    background-color: transparent !important
  @media screen and (max-width: $compactdesktop)
    max-width: 90vw

@use "sass:math"

.rail
  &
    background: $white
    box-shadow: $rail-shadow
    padding-bottom: rem(128px)
    width: rem(275px)
    padding-top: 0

    @media screen and (max-width: $desktop)
      padding-bottom: 0
      visibility: hidden

    @media screen and (max-width: $tinymobile)
      width: rem(250px)

  &.is-visible-on-mobile
    @media screen and (max-width: $desktop)
      visibility: initial

  .show-on-focus
    position: fixed
    clip: rect(1px 1px 1px 1px)
    clip: rect(1px, 1px, 1px, 1px)
    padding: 0
    border: 0
    height: 1px
    width: 1px
    overflow: hidden
    white-space: nowrap
    &:focus,
    &:active
      overflow: visible
      height: auto
      width: auto
      clip: auto

.rail-close-button
  top: 1rem

.user
  align-items: center
  border-top: rem(1px) solid $color-rail-borders
  border-bottom: rem(1px) solid $color-rail-borders
  display: flex
  flex-direction: column
  padding: rem($baseline) 0
  text-align: center

  a
    align-items: center
    color: $color-link-profile
    cursor: pointer
    display: flex
    mpv-icon
      margin-right: rem(2px)

  &-mpv
    font-size: rem(12px)
    line-height: rem(12px)
  &__profile
    align-items: center
    display: flex
    flex-direction: column
    font-size: rem(12px)
    line-height: rem(13px)
    p, a
      margin: 0 rem(2px)
    &--popover
      position: relative
      display: inline-block
      &-content
        background-color: white
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
        color: $blue-600
        display: none
        min-width: 28rem
        padding: 1rem
        position: absolute
        right: -20px
        text-align: left
        top: -15px
        transform: translateX(100%)
        z-index: 10
        .icon-close
          cursor: pointer
          position: absolute
          right: 5px
          top: 5px
        &::before
          content: ""
          border-style: solid
          border-width: 10px 15px 10px 0
          border-color: transparent white transparent transparent
          left: -15px
          position: absolute
          height: 0
          top: 12px
          width: 0

      @media (max-width: $screen-lg)
        .user__profile--popover-content
          top: 29px
          left: 50%
          transform: translateX(-50%)
          min-width: 22rem
          &::before
            border-width: 0 10px 15px 10px
            border-color: transparent transparent white transparent
            left: 50%
            top: -15px
            transform: translateX(-50%)

      &.opened
        &::after
          background: 0 0
          box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.15)
          content: ''
          height: 0
          left: 0
          position: absolute
          top: 0
          width: 100%
          z-index: 1
        .user__profile--popover-content
          display: block

  &-name
    float: right
    font-size: rem(15px)
    font-weight: 600
    width: 58%

  &-logout
    color: $blue-500
    font-size: rem(13px)
    font-weight: 600

  &__rail-options
    display: flex
    justify-content: space-between
    left: rem(10px)
    position: absolute
    top: rem(5px)
    width: calc(100% - 2rem)

  &__avatar
    cursor: pointer
    margin-bottom: rem($baseline * 0.4)
    position: relative
    span
      align-items: center
      border-radius: 50%
      color: white
      display: flex
      font-size: 1.2rem
      font-weight: bold
      height: 100%
      left: 0
      line-height: 1.3rem
      position: absolute
      top: 0
      transition: all 0.3s
      width: 100%
      &:hover
        background-color: rgba(0,0,0,0.2)
        font-size: 1.3rem

  @media screen and (max-width: $desktop)
    .user__rail-options
      bottom: rem(5px)
      top: initial
      li
        margin: rem(5px)
        a
          font-size: rem(15px)

.railnav

  position: relative

  a:hover
    text-decoration: none

  &-heading
    height: rem(40px)
    line-height: rem(18px)
    width: 100%
    background: $white !important

    &-toggle-container
      width: rem(40px)

    &-toggle
      cursor: pointer
      color: $blue-500
      margin: rem(6px 14px 0 7px)
      @media screen and (max-width: $desktop)
        display: none

    &-description,
    &-value
      font-size: rem(12px)
      font-weight: bold
      text-transform: uppercase
      vertical-align: middle

    &-description
      width: 65%

    &-value
      cursor: pointer
      button
        margin-right: rem(20px)

  &-collapsed
    color: $blue-500
    cursor: pointer
    text-align: center

    &-icon
      border-radius: 50%
      height: rem($baseline * 4)
      margin: 0 auto rem($baseline)
      padding-top: rem(math.div($baseline, 4) * 3)
      width: rem($baseline * 4)

      button
        color: $white

    &-description
      font-size: rem(16px)
      margin: 0 rem($baseline * 3) rem(math.div($baseline, 4))

      button
        color: $blue-500
        font-family: $font-stack-sans-serif
        font-size: rem(16px)

    &-value
      font-size: rem(14px)

  .open-accounts
    background: $color-row-odd

    tr td.railnav-account
      font-size: rem($baseline * 1.3)

  &-accounts
    border-collapse: collapse
    border-top: rem(1px) solid $color-rail-borders
    border-bottom: rem(1px) solid $color-rail-borders
    margin-bottom: rem(-1px)
    width: 100%

    a
      color: $color-rail-account-links

    th
      text-align: left

    tr
      td
        cursor: pointer
        padding: rem(10px 0)

    tr.railnav-accounts-firstrow
      box-shadow: 0 10px 10px -10px inset rgba(0,0,0,0.3)

    tr.railnav-accounts-oddrow
      background: $color-row-odd
    tr.railnav-accounts-evenrow
      background: $color-row-even
    &-heading
      background: $white !important

    th.railnav-accounts-heading-name,
    td.railnav-account
      text-align: left
      width: 70%

      a

        small
          color: $color-rail-account-links-secondary
          display: block

        span
          display: block
          font-size: rem($baseline * 1.3)

    td.railnav-account
      padding-left: 3px

    td.railnav-account-all
      // same padding as <th>
      padding-left: 1px !important

    th.railnav-accounts-heading-name
      font-size: rem(13px)
      font-weight: 600
      text-transform: uppercase

      .railnav-account-name
        text-transform: none

    td.railnav-accounts-heading-toggle,
    td.railnav-account-toggle
      padding: rem(0 13px)
      text-align: center

    td.railnav-accounts-heading-value,
    td.railnav-account-value
      color: $color-rail-account-links
      font-size: rem(13px)
      padding-right: 30px
      text-align: right
      vertical-align: top
      width: 15%
      span
        border-bottom: 1px dotted

    td.railnav-account-value
      padding-top: rem(15px)

      a
        color: $color-rail-account-links

      .railnav-account-value-text
        // override <p> tag default margins
        margin-top: 0rem
        margin-bottom: 0rem

    tr.is-active
      background: $default-primary-color

      *
        color: $color-primary-background-text !important

    table.open-accounts
      line-height: rem(17px)

.account-dot
  box-sizing: content-box
  border: rem(1px) solid
  border-radius: 50%
  height: rem(math.div($baseline, 2))
  width: rem(math.div($baseline, 2))
  .is-active &
    border-color: $white !important
    background: $white !important

.accounts-toggle
  background: #d7d7d7
  border-radius: 50%
  color: $white
  display: block
  height: rem($baseline * 1.5)
  width: rem($baseline * 1.5)
  .icon
    position: relative
    top: rem(-1px)

.is-collapsedrail
  .rail
    width: rem(182px)
    .is-visible-on-mobile &
      width: rem(275px)

  .masthead
    &-ourbrand
      margin: rem($baseline) rem($baseline * 2) 0

    &-locatedat
      margin: rem(7px) 0

    &-cobrand
      margin: 0 rem($baseline * 3) rem($baseline * 3.42)

  .user
    padding: rem($baseline * 1) 0

    &-img
      display: block
      float: none
      margin: 0 auto

    &-mpv,
    &-name-full
      display: none

    &-name
      margin-top: rem(-10px)
      padding: 0
      text-align: center
      width: 100%

    &-logout
      display: inline-block
      text-align: center

  .railnav

    &-accountscontainer
      background: $white
      border: rem(1px) solid $color-rail-borders
      box-shadow: $rail-shadow
      position: absolute
      top: 0
      left: rem(182px)
      width: rem(275px)
      z-index: 1000

    &-heading-toggle .is-collapsedrail-show
      @media screen and (max-width: $desktop)
        display: none

.rail-collapse
  display: none
  &.is-visible-on-mobile
    display: none
    @media screen and (max-width: $desktop)
      background: $white
      border-radius: 100%
      display: block
      padding: rem(18px 21px)
      position: fixed
      left: rem(295px)
      top: 25%
      z-index: 10000
    @media screen and (max-width: $tinymobile)
      padding: rem(13px 16px)
      left: rem(260px)

    mpv-icon
      color: $blue-500
      cursor: pointer

$module-background: #fff

.module
  &
    background: $module-background
    box-shadow: 1px 1px 3px rgba($color-text, 0.3)
    display: block
    vertical-align: top
    @media screen and (max-width: $mobile)
      min-height: auto
    @media screen and (min-width: $compactdesktop)
      margin-left: rem(20px)


  &-heading
    @extend .split
    @include rem(line-height, 48px)
    border-bottom: 2px solid $color-module-border
    display: block
    overflow: hidden
    padding: rem(0 16px 0)
    text-align: left

    &.border-b
      border-bottom-width: 1px

    h3
      float: left
      font-size: rem(12px)
      font-weight: 600
      letter-spacing: rem(1px)
      text-transform: uppercase

  &-actions
    display: inline-block
    text-align: right

    &.flex
      display: flex !important

    button
      margin-left: rem(12px)

    .icon-link
      .icon
        svg
          fill: $color-link-action

  h2
    font-size: rem(16px)
    font-weight: 600
    margin-bottom: rem(20px)

  &-content
    padding: rem(25px 15px 10px)
    overflow: auto

    &-meta

      &
        margin: 0 0 rem($baseline * 1.5)
        text-align: right

      a
        display: block

      .date-range
        font-size: rem(12px)

    .activity-pending,
    .activity-history
      background: linear-gradient(#f2f2f2, $white, $white)
      margin-bottom: 0
      overflow: hidden
      padding: rem($baseline * 1.5)

      h4
        color: $color-module-title
        display: inline-block
        font-size: rem(16px)
        font-weight: 600

      .module-actions
        color: $color-module-action-text
        float: right

    .activity-pending

      & + .activity-history
        margin-top: rem($baseline)

  &-footer

    &
      color: $color-module-footer
      display: block
      font-size: rem(12px)
      letter-spacing: rem(1px)
      overflow: hidden
      padding: rem(15px 15px 5px)

    p.as-of-date
      float: right


.chart-settings
  margin: rem(10px 25px)

  &-by
    display: inline-block
    margin: 0 rem($baseline * 2)

  button
    border-left: rem(1px) solid $color-module-border !important
    border-right: rem(1px) solid $color-module-border !important
    color: $blue-500
    cursor: pointer
    font-size: rem($baseline)
    font-weight: bold
    margin-right: rem(-1px)
    padding: rem(5px 15px)

    &:last-child
      border-right: 0

    &.selected
      border-bottom: rem(2px) solid
      color: $black

    &:hover
      background: $blue-500
      color: $white

.positionsFilter
  width: 100%
  padding: rem(7px) rem(7px) rem(7px) 0
  background: $color-filter-background

.tabs
  display: flex
  margin: rem(8px) auto

  li
    flex: 1
    margin: 0 rem(4px)

    button
      border-radius: rem(30px)
      font-size: rem(16px)
      font-weight: 700
      line-height: initial
      padding: rem(7px 20px) !important

html
  @include rem-baseline

body
  @include rem(line-height, $baseline * 1.5)
  color: $color-text
  font-size: rem(14px)
  font-kerning: normal

.reset-lh
  @include rem(line-height, $baseline * 1.5)



@use "sass:math"

.plating
  &
    background: $default-primary-color
    color: $color-primary-background-text
    height: rem(210px)
    padding: rem(20px 0 0 20px)
    @media screen and (max-width: $desktop)
      height: auto
      padding: 0

  &-header-mobile
    display: none
    @media screen and (max-width: $desktop)
      background: $white
      color: $color-text
      display: block
      font-size: rem($baseline)
      overflow: hidden
      text-align: center

    mpv-icon
      color: $white
      cursor: pointer

    &-toggle
      background: $default-primary-color
      border-radius: 100%
      float: left
      margin: rem(10px)
      padding: rem(7px 8px 5px 10px)

    &-title
      float: left
      margin: rem(15px) auto
      font-size: 16px
      font-weight: bold
      width: 65%

  &-header
    font-size: rem(28px)
    letter-spacing: 1px
    line-height: rem(28px)
    min-height: rem(32px)
    max-height: rem(100px)
    padding: 0
    transition: all 0.8s
    @media screen and (max-width: $desktop)
      padding: rem(0 15px)
      &.hide-title
        max-height: rem(20px)

        .plating-header-text
          opacity: 0

    &__title
      @media screen and (max-width: $compactdesktop)
        max-width: 85%

    &-text
      transition: all 0.8s
      @media screen and (max-width: $compactdesktop)
        width: -webkit-fill-available
      @media screen and (max-width: $desktop)
        padding-bottom: rem(10px)
        padding-top: rem(25px)

      .floating-label
        position: absolute
        font-size: 14px
        color: $white
        margin: -2.5rem 0 0 0.5rem
        animation: fadeIn ease 1s

    .icon
      @include rem(line-height, 28px)
      background: rgba(0, 0, 0, 0.5)
      border-radius: 100%
      cursor: pointer
      height: 32px
      margin-left: rem(12px)
      text-align: center
      transition: background $transition-duration ease-in-out
      width: 32px
      justify-content: center
      align-items: center
      @media screen and (max-width: 471px)
        height: 38px
        width: 38px

      &:hover
        background: rgba(255, 255, 255, 0.25)
    .icon-download
      background: none
      border-radius: 0
      align-items: center
      height: rem(15px)
      justify-content: center
      width: rem(25px)
      margin-left: 0
      &:hover
        background: none

    input
      border: rem(1px) solid $color-module-border
      float: left
      font-size: rem(16px)
      margin-right: rem(math.div($baseline, 2))
      padding: rem(3px)
      color: $color-text
      line-height: normal

    button:not(.btn-download)
      background: $white
      border: rem(1px) solid $white
      border-radius: rem(20px)
      color: $color-text
      cursor: pointer
      display: inline-block
      float: left
      font-size: rem($baseline)
      margin: 0 rem(math.div($baseline, 2)) 0 0
      padding: rem(5px 15px)
      text-transform: uppercase
      line-height: normal

      &:hover
        background: $blue-500
        border: rem(1px) solid $blue-500
        color: $white

  &-figures

    &
      display: block
      margin: rem(24px 0 0)
      overflow: hidden
      @media screen and (max-width: $desktop)
        margin: 0
        position: relative

    .grid-figures
      grid-template-columns: 100% 1fr

    figure
      background: $color-plating-figures-background
      float: left
      margin: 0
      @media screen and (max-width: $mediummobile)
        clear: both
        float: none

    .plating-assets

      &
        background-color: transparent
        margin: rem(7px 0 0 0)
        width: 32%
        @media screen and (max-width: $mobile)
          margin-left: rem(15px)
          width: 40%
        @media screen and (max-width: $screen-sm)
          grid-row: 2
          width: 100%

      figure
        background: transparent
        border: 0
        display: block
        float: none
        height: auto

      figcaption
        font-size: rem(10px)
        font-weight: normal
        margin: 0
        text-transform: uppercase
        @media screen and (max-width: $desktop)
          font-size: rem($baseline * 1.2)

      .plating-assets-value
        border-left: rem(3px) solid $default-secondary-color
        font-size: rem(36px)
        height: rem(48px)
        margin: rem(0 0 15px 0)
        padding-left: rem(12px)
        @media screen and (max-width: $desktop)
          font-size: rem(28px)
        @media screen and (max-width: $desktop)
          font-size: rem($baseline * 3.4)

        figcaption
          @include rem(line-height, 12px)
          margin: rem(-2px 0 0 4px)

        &-amount
          display: block
          margin: rem(10px 0 0)

        &-currency
          font-size: rem(12px)
          font-weight: normal
          @media screen and (max-width: $desktop)
            font-size: rem($baseline * 1.5)

      .plating-assets-change
        font-size: rem(17px)
        margin-left: rem(15px)
        @media screen and (max-width: $desktop)
          font-size: rem($baseline * 2)
          margin-bottom: rem($baseline * 2)

    .plating-document

      &
        color: $color-text
        height: rem(105px)
        overflow: initial
        padding: 0
        position: relative
        width: 100%
        &:before
          content: ""
          height: 100%
          left: -1px
          position: absolute
          width: 5px
          z-index: 1
        @media screen and (max-width: $desktop)
          height: 100%
          width: 100%

      &-latest
        display: block
        margin: 0

        a
          padding: 0
          text-decoration: none

        p
          margin: 0
          padding: rem(8px 0px 0px 15px)

        span
          display: block

      &-kind
        color: $color-plating-label
        font-size: rem(11px)
        font-weight: bold
        margin-bottom: rem(6px)
        text-transform: uppercase

      &-name
        color: $color-text
        font-size: rem(16px)
        margin-bottom: rem(2px)

      &-info
        color: $color-plating-text-meta
        font-size: rem(13px)

      &-all
        border-top: 1px solid $color-plating-hr
        color: $blue-500
        display: block
        font-size: rem(12px)
        font-weight: bold
        letter-spacing: rem(1px)
        overflow: hidden
        text-align: center
        text-decoration: none
        text-transform: uppercase
        vertical-align: center
        width: 100%

        @media screen and (max-width: $desktop)
          bottom: 0
          position: absolute

        p
          margin: 0
          padding: 0
          position: relative

        a
          height: rem(25px)
          text-decoration: none
          width: 100%

          &:hover
            background: $blue-500
            color: $white
            @media screen and (max-width: $desktop)
              background: $white
              color: $blue-500

      &-loading,
      &-nodocs
        margin: rem($baseline)

      &-fake
        background-color: transparent

    .plating-advisor

      &
        color: $color-text
        float: right
        height: rem(105px)
        max-width: 30%
        min-width: 28%
        position: relative
        text-align: center

        @media screen and (max-width: $desktop)
          height: 100%
          width: 100%
          display: flex
          background: $white
          float: none
          max-width: none
          min-width: auto
          text-align: left
          padding-left: 0

      .avatar
        left: rem(-35px)
        position: absolute
        top: 0
        height: rem(70px)
        width: rem(70px)
        @media screen and (max-width: $desktop)
          height: rem(50px)
          left: rem(-25px)
          width: rem(50px)
        @media screen and (max-width: $desktop)
          box-shadow: $rail-shadow
          height: rem(70px)
          left: rem(20px)
          position: relative
          width: rem(70px)

      .advisor-name
        color: $color-text
        font-size: rem(16px)
        font-weight: normal
        text-transform: none
        margin: 0


        &.long-name
          font-size: rem(14px)

        &.longer-name
          font-size: rem(12px)

        @media screen and (max-width: $desktop)
          font-size: rem($baseline * 1.3)

      .advisor-name-role
        color: $color-plating-label
        display: block
        font-size: rem(10px)
        font-weight: bold
        margin: 0
        text-transform: uppercase

      ul li a
        color: $color-link-icon

  &-banners
    background: none !important
    height: rem(105px)
    width: 34%
    @media screen and (max-width: $screen-xl)
      height: auto
      margin-bottom: 1rem !important
      width: 40%
    @media screen and (max-width: $screen-lg)
      width: 50%
    @media screen and (max-width: $screen-sm)
      margin-left: 1.5rem !important
      grid-row: 1
      width: 100%

  .grid-figures
    @media only screen and (min-width: $screen-sm) and (max-width: $screen-lg)
      .plating-assets
        float: left
      .plating-banners
        float: right
        margin: 0 1.5rem 1rem 0 !important

.overlay-actions
  overflow: hidden
  padding: rem(math.div($baseline, 2)) 0 rem(math.div($baseline, 4))
  display: flex
  justify-content: flex-end
  align-items: center

  p
    padding: auto 0

    a.btnsecondary:hover
      text-decoration: none

.branding
  padding-top: 3.6rem

  img
    height: 11rem

  &.cobranded
    padding-top: 1.2rem

    img
      height: 4.2rem

      &.cobrand
        height: 8.2rem

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

.long-name-padding
  padding-left: rem(35px)

*,
*::before,
*::after
  box-sizing: border-box

body
  padding: 5rem 0 0 0

  @media (min-width: $screen-xl)
    padding-top: 0

html, body
  background: $color-background
  font-family: $font-stack-sans-serif
  margin: 0
  height: 100%

  ::placeholder
    color: $color-text-secondary

a
  color: $blue-500
  text-decoration: none

  &:hover
    text-decoration: underline

h1,
h2,
h3,
h4,
h5,
h6
  margin: 0
  padding: 0

p
  max-width: rem(100%)

button
  background: none

abbr
  border-bottom: 1px dashed
  cursor: pointer

router-outlet
  display: none

[hidden]
  display: none !important

.passwordReqUL
  list-style-type: none
  padding-left: 20px

.oneline
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.w-available
  width: -webkit-fill-available

mpv-customer-phone,
mpv-mobilephonenumber-verification,
mpv-referral-form
  p-inputMask
  input.number-input
    margin-right: 0.5rem
    border: solid 1px black
    padding: 0.5rem

    @media (min-width: $screen-sm)
      margin-right: 1.5rem

  p-inputMask.ng-dirty.ng-invalid, p-inputMask.error
    input.number-input
      background: lighten($color-alert-background, 47%)
      border-color: $color-alert-background
      outline-color: $color-alert-background

.bg-red
  background: #ff3d00

.text-red
  color: #ff3d00

.text-green
  color: #00b100

.bg-thead
  background-color: $color-row-odd

.text-large
  font-size: 1.8rem

#survicate-box .sv-box-child div
  right: 30px
  left: auto !important

.min-w-100
  min-width: 100px

.leading-initial
  line-height: initial

.tooltip
  position: relative
  display: inline-block

  .tooltiptext
    visibility: hidden
    width: 120px
    background: #fff
    color: #4c4c4c
    text-align: center
    padding: 5px 0
    border-radius: 6px
    position: absolute
    z-index: 1
    filter: drop-shadow(0 3px 5px #ccc)

  &:hover .tooltiptext
    visibility: visible

  .tooltiptext
    bottom: 100%
    left: 50%
    margin-left: -60px
    width: 250px
    padding: 1.1rem
    font-size: 12px
    margin-left: -125px
    margin-bottom: 5px

    &::after
      content: " "
      position: absolute
      top: 100%
      left: 50%
      margin-left: -5px
      border-width: 5px
      border-style: solid
      border-color: #fff transparent transparent transparent

      opacity: 0
      transition: opacity 0.5s

  &:hover .tooltiptext
    opacity: 1
